import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

const GenderSelect = () => {
  const [gender, setGender] = useState('')

  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },
    { value: 'Undisclosed', label: 'Undisclosed' },
  ]

  return (
    <div className='form-group'>
      <label>Gender Select<span className="text-red-700">*</span></label>
      <Select
        options={genderOptions || []}
        onChange={setGender}
        styles={customStyles}
        required={true}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
      />
      <input type='hidden'
             name={`issue[patient_profile_attributes][gender]`}
             value={gender?.value} />
    </div>
  )
}

const customStyles = {
  control: base => ({
    ...base,
    height: 46,
    minHeight: 46,
    border: "1px solid rgb(203 213 225)",
  })
};

export default GenderSelect
