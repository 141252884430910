import React from "react";
import Select from "react-select";

const QuestionSelect = ({ question, name, required }) => {
  const options = question.options.map(option => {
    return { label: option, value: option }
  })

  return <>
    <Select options={options} className='w-64' name={name} required={required}
            menuPortalTarget={document.body}
            menuPosition={'fixed'} />
  </>
}
export default QuestionSelect
