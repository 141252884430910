import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'

const ProvinceSelect = () => {
  const [selectedProvince, setProvince] = useState('')

  const provinceOptions = [
    { value: 'ontario', label: 'Ontario' },
    { value: 'alberta', label: 'Alberta' },
  ]

  const isDisabled = !!!selectedProvince
  return (
    <div className='form-group gap-y-4'>
      <label>Province Select<span className="text-red-700">*</span></label>
      <Select
        options={provinceOptions || []}
        onChange={setProvince}
        styles={customStyles}
        required
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
      />
      <a className={isDisabled ? 'btn btn-disabled text-center' : 'btn btn-blue text-center'}
         href={isDisabled ? event.preventDefault() : whichURL(selectedProvince.value)}>Continue</a>
    </div>
  )
}

const whichURL = (province) => {
  return `https://${province}.consultmd.com/caregivers`
}

const customStyles = {
  control: base => ({
    ...base,
    height: 46,
    minHeight: 46,
    border: "1px solid rgb(203 213 225)",
  })
};

export default ProvinceSelect
