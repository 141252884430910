import React from 'react'

export const provincialFormatPattern = (province) => {
  switch (province) {
    case "ONTARIO":
      return '9999-999-999-aa'
    default:
      return '99999-9999'
  }
}
