import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Transition } from "@headlessui/react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export default function Notice({ notice, alert }) {
  const [show, setShow] = useState(false)
  const timeoutID = useRef()

  useEffect(() => {
    const notify = async () => {
      if (notice || alert) setShow(true)
      const id = await setTimeout(() => setShow(false), 3000)
      timeoutID.current = id
      return id
    }

    notify()

    return () => {
      clearTimeout(timeoutID.current)
    }
  }, [])

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none relative flex items-end h-0 sm:items-start z-50"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="pointer-events-auto absolute inset-x-0 top-10 w-96 max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {notice ?
                    <FontAwesomeIcon icon={faCheckCircle} className="h-6 w-6 text-green-400" aria-hidden="true" /> :
                    <FontAwesomeIcon icon={faExclamationCircle} className="h-6 w-6 text-red-400" aria-hidden="true" />}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900">{alert ?? notice}</p>
                </div>
                <div className="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon icon={faTimesCircle} className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}
