import React from 'react'
import QuestionSelect from "./questionSelect";
import AnatomyInput from './anatomyInput';
import DurationSelects from './durationSelects'

const IssueQuestion = ({ i, question, onSelect }) => {
  const renderFormField = () => {
    switch (question.type) {
      case 'text':
        return <textarea name={`issue[responses_attributes][${i}][value]`} required={question.required} />
      case 'string':
        return <input type='text' name={`issue[responses_attributes][${i}][value]`} required={question.required} />
      case 'select':
        return <QuestionSelect question={question} name={`issue[responses_attributes][${i}][value]`}
                               required={question.required} />
      case 'file':
        return (
          <div className='pb-6'>
            <input type='file' name={`issue[responses_attributes][${i}][files][]`} multiple
                   required={question.required} onChange={onSelect} />
            <label className='pt-4'>Image Description</label>
            <input type='text' name={`issue[responses_attributes][${i}][value]`} required={question.required} />
          </div>
        )
      case 'diagram':
        return <AnatomyInput name={`issue[responses_attributes][${i}][value]`} />
      case 'two_selects':
        return <DurationSelects question={question} name={`issue[responses_attributes][${i}][value]`} required={question.required} />
    }
  }

  return <div className='form-group'>
    <div className='flex items-center gap-x-1 text-red-700'>
      <label>{question.description}</label>
      {question.required && <span>*</span>}
    </div>
    <div className='flex flex-col'>
      <input type='hidden' name={`issue[responses_attributes][${i}][question_key]`} defaultValue={question.key} />
      <input type='hidden' name={`issue[responses_attributes][${i}][question_type]`} defaultValue={question.type} />
      {renderFormField()}
    </div>
  </div>
}

export default IssueQuestion
