import React from "react"
import { DisplayValue } from "../../../utilities/displayValue"
import { format } from "date-fns";

const IssueRow = ({ issue, isSelected, toggleSelectIssue, onNavigate }) => {
  console.log(issue)
  return (
    <tr className={issue?.id ? 'cursor-pointer' : 'cursor-not-allowed'}>
      <td>
        <div className="flex justify-center align-center">
          <input
            type="checkbox"
            className="h-5 w-6"
            checked={isSelected}
            onChange={() => toggleSelectIssue()}
          />
        </div>
      </td>
      <td onClick={() => onNavigate(issue)}>{DisplayValue(issue.patient_profile?.first_name)}</td>
      <td onClick={() => onNavigate(issue)}>{DisplayValue(issue.patient_profile?.last_name)}</td>
      <td onClick={() => onNavigate(issue)}>{DisplayValue(issue.patient_profile?.gender)}</td>
      <td onClick={() => onNavigate(issue)}>{DisplayValue(issue.patient_profile?.personal_health_number)}</td>
      <td onClick={() => onNavigate(issue)}>{DisplayValue(issue.patient_profile?.dob)}</td>
      <td onClick={() => onNavigate(issue)}>{DisplayValue(issue.primary_physician.name)}</td>
      <td onClick={() => onNavigate(issue)}>{DisplayValue(issue.get_consultant)}</td>
      <td onClick={() => onNavigate(issue)}>{DisplayValue(issue?.completed_at)}</td>
      <td className='overflow-ellipsis' onClick={() => onNavigate(issue)}>{DisplayValue(issue?.diagnosis)}</td>
    </tr>
  )
}



export default IssueRow
