import React, { useMemo, useState } from 'react'
import { InputMask } from 'primereact/inputmask'
import { provincialFormatPattern } from "../../utilities/provincialFormatPattern";

const FormatPersonalHealthHumber = ({ value }) => {
  const [inputValue, setInputValue] = useState(value ?? '')
  const maskPattern = useMemo( () => provincialFormatPattern(process.env.REACT_APP_PROVINCE), [process.env.REACT_APP_PROVINCE])

  return (
    <InputMask
      name='issue[patient_profile_attributes][personal_health_number]'
      mask={maskPattern}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
    />
  )
}


export default FormatPersonalHealthHumber
