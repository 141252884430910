import React, { useState, useEffect, useMemo } from 'react'

const SubspecialtyRadioSelect = ({ subspecialty, currentSubspecialties }) => {
  const [isChecked, setIsChecked] = useState(null)
  const [selectedSubSpecialty, setSelectedSubspecialty] = useState(null)

  const onChange = () => {
    setIsChecked(prev => !prev)
    setSelectedSubspecialty(subspecialty.value)
  }

  useEffect(() => {
    matchSubspecialties()
  }, [subspecialty])

  const matchSubspecialties = () => {
    currentSubspecialties.map((currentSpecialty) => {
      if(currentSpecialty.id === subspecialty.value)
        setIsChecked(!isChecked)
    })
  }


  return (
    <div className='flex items-center'>
      <input type="checkbox" id={subspecialty.label} name='subspecialty' value={subspecialty.value}
             onChange={onChange} checked={isChecked} />
      <label className='text-sm px-1'>{subspecialty.label}</label>
      {isChecked && <input type='hidden'
                           name={`caregiver[caregiver_specialties_attributes][][specialty_id]`}
                           value={selectedSubSpecialty}
      />}


    </div>
  )
}

export default SubspecialtyRadioSelect
