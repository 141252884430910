import React from 'react'
import Logo from '../../../assets/images/consultMD_logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { AdminNavigation } from "../../utilities/navigation"
import ProvinceLabel from "../shared_components/provinceLabel";

const SideNav = () => {
  return (
    <div>
      <div className='flex lg:flex-col flex-auto text-gray-800'>
        <div className='lg:fixed flex flex-col top-0 left-0 w-full lg:w-64 bg-blue-100 h-full shadow-lg'>
          <div className='flex items-center justify-start flex-col py-6 pl-6 h-20'>
            <a href='/'>
              <img src={Logo} className='h-7' alt='ConsultMD' />
            </a>
          <ProvinceLabel textSize='text-sm' />
          </div>
          <div className=' overflow-y-auto overflow-x-hidden flex-grow'>
            <ul className='flex flex-row md:flex-col py-6 space-y-1'>
              <div>
                <li className='px-5'>
                  <div className='flex flex-row items-center h-8'>
                    <div className='flex font-semibold text-sm text-gray-700 my-4 font-sans uppercase'>
                      Dashboard
                    </div>
                  </div>
                </li>
                {AdminNavigation.map((item) => {
                  return <li>
                    <a
                      href={item.href}
                      className='relative flex flex-row items-center h-11 focus:outline-none hover:bg-blue-200 text-gray-600  border-l-4 border-opacity-0 hover:border-blue-500 pr-6'
                    >
                    <span className='inline-flex justify-center items-center ml-4'>
                      <FontAwesomeIcon icon={item.icon} />
                    </span>
                      <span className='ml-2 font-semibold text-sm tracking-wide font-sans'>
                      {item.name}
                    </span>
                    </a>
                  </li>
                })}
              </div>
              <div>
                <li className='px-5'>
                  <div className='flex flex-row items-center h-8'>
                    <div className='flex font-semibold text-sm text-gray-700 my-4 font-sans uppercase'>
                      Settings
                    </div>
                  </div>
                </li>
                <li>
                  <a
                    href='/admins/sign_out'
                    data-method='delete'
                    className='relative flex flex-row items-center h-11 focus:outline-none hover:bg-red-300 text-gray-600 hover:text-gray-200 border-l-4 border-opacity-0 hover:border-red-500 pr-6'
                  >
                    <span className='inline-flex justify-center items-center ml-4 text-red-400'>
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </span>
                    <span className='ml-2 font-semibold text-sm tracking-wide truncate font-sans'>
                      Logout
                    </span>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideNav
